import React from 'react';

const IconMycelio = props => {
  return (
    <svg viewBox="0 0 582 582" {...props}>
      <g fill="none" fillRule="evenodd" strokeWidth={2}>
        <g stroke="#979797">
          <path d="M349.435 45.176c0 7.412-4.147 13.765-10.564 13.765s-10.565-6.353-10.565-13.765c0-7.411 2.113-11.647 10.565-11.647s10.564 4.236 10.564 11.647z" />
          <path d="M321.968 63.176l10.564-6.352" />
          <path d="M345.21 56.824l14.79 8.47v4.235c0 3.507-2.113 6.353-4.226 6.353h-12.677" />
          <g>
            <path d="M239.565 45.176c0 7.412 4.147 13.765 10.564 13.765s10.565-6.353 10.565-13.765c0-7.411-2.113-11.647-10.565-11.647s-10.564 4.236-10.564 11.647z" />
            <path d="M267.032 63.176l-10.564-6.352" />
            <path d="M243.79 56.824L229 65.294v4.235c0 3.507 2.113 6.353 4.226 6.353h12.677" />
          </g>
          <g>
            <path d="M248.016 114V94.941c0-21.176 14.79-19.059 29.58-27.53" />
            <path d="M311.403 67.412c14.79 8.47 29.58 6.353 29.58 27.53V114" />
            <path d="M302.952 75.882l-8.452-4.235-8.452 4.235-8.451-8.47 4.226-6.353 12.677 2.117 12.677-2.117 4.226 6.353z" />
            <path d="M305.065 52.588v8.47" />
            <path d="M283.935 61.059v-8.47" />
            <path d="M292.387 90.706h4.226" />
            <path d="M292.387 82.235h4.226" />
            <path d="M313.516 25.059C313.516 12.194 307.336 6 294.5 6c-12.836 0-19.016 6.194-19.016 19.059v8.47c2.113 14.824 8.451 19.06 8.451 19.06 4.226 2.117 10.565 2.117 10.565 2.117s6.339 0 10.565-2.118c0 0 6.338-4.235 8.451-19.059v-8.47z" />
            <path d="M275.484 25.059l8.451-4.235 10.565 4.235 10.565 4.235 8.451-4.235" />
            <path d="M264.92 114V97.059" />
            <path d="M324.08 114V97.059" />
          </g>
        </g>
        <g stroke="#979797">
          <path d="M564.965 499.333l12.8-121.78c.68-6.47-4.025-12.263-10.51-12.945l-61.053-6.417c-6.486-.681-12.292 4.008-12.972 10.476l-12.8 121.78c-.68 6.47 4.025 12.263 10.51 12.945l61.053 6.417c6.486.681 12.292-4.008 12.972-10.476z" />
          <path d="M501.008 380.772l66.633 7.004-10.767 102.435-66.633-7.003z" />
          <path d="M516.938 497.074l8.95.941" />
          <path d="M525.032 372.237l-4.973-.523" />
          <path d="M529.01 372.655l23.868 2.508" />
          <path d="M503.534 471.534l4.973.522" />
          <path d="M513.48 472.579l4.972.522" />
          <path d="M521.436 473.415l4.972.523" />
          <path d="M531.381 474.46l4.973.523" />
          <path d="M540.332 475.401l4.972.523" />
          <path d="M511.374 396.944l4.973.523" />
          <path d="M521.32 397.99l4.972.522" />
          <path d="M529.275 398.826l4.973.523" />
          <path d="M539.22 399.871l4.973.523" />
          <path d="M548.171 400.812l4.973.523" />
          <path d="M510.224 407.884l4.973.523" />
          <path d="M520.17 408.93l4.972.522" />
          <path d="M528.126 409.766l4.972.522" />
          <path d="M538.07 410.81l4.973.524" />
          <path d="M547.022 411.752l4.972.522" />
          <path d="M508.97 419.818l4.972.523" />
          <path d="M518.915 420.864l4.973.522" />
          <path d="M526.871 421.7l4.973.523" />
          <path d="M536.816 422.745l4.973.523" />
          <path d="M545.767 423.686l4.973.523" />
          <path d="M507.82 430.758l4.973.523" />
          <path d="M517.765 431.803l4.973.523" />
          <path d="M525.721 432.64l4.973.522" />
          <path d="M535.667 433.685l4.972.523" />
          <path d="M544.617 434.626l4.973.522" />
          <path d="M506.461 443.687l4.973.523" />
          <path d="M516.406 444.732l4.973.523" />
          <path d="M524.363 445.568l4.972.523" />
        </g>
        <path
          id={props.css.line1}
          fillRule="nonzero"
          stroke="#8BC53F"
          d="M219.414 65.169C159.247 85.748 113.14 121.356 81.096 171.995 49.051 222.633 38.15 282.325 48.39 351.072"
        />
        <path
          id={props.css.line3}
          fillRule="nonzero"
          stroke="#8BC53F"
          d="M375.106 66.828c57.953 23.476 102.372 59.07 133.257 106.78 30.886 47.71 41.499 106.568 31.84 176.574"
        />
        <path
          id={props.css.line2}
          fillRule="nonzero"
          stroke="#8BC53F"
          d="M470.745 481.46s-66.127 72.4-177.855 72.4c-66.02 0-125.305-24.753-177.856-74.258"
        />
        <g stroke="#A0A0A0" strokeLinejoin="round">
          <path d="M83.84 461.453V482" />
          <path d="M92.96 454.604V482" />
          <path d="M77 372.415L88.4 361l29.64 29.68h-13.68l20.52 20.546h-15.96l22.8 22.83h-18.24L134 454.605h-29.64" />
          <path d="M46.4 461.453V482" />
          <path d="M55.6 454.604V482" />
          <path d="M67.1 390.68l20.7 20.546H71.7l23 22.83H76.3L97 454.605H5l20.7-20.547H7.3l23-22.83H14.2l20.7-20.548H21.1L51 361l29.9 29.68z" />
          <path d="M51 409v4" />
          <path d="M51 431v4" />
          <path d="M51 389v4" />
        </g>
      </g>
    </svg>
  );
};

export default IconMycelio;
